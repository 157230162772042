import {loadJs} from './utils'

var selected_device;

if (!selected_device) {
	loadJs('/BrowserPrint-3.0.216.min.js').then(()=> {
		BrowserPrint.getDefaultDevice("printer", function(device) {
			selected_device = device;
		})
	});
}

function writeToSelectedPrinter(dataToWrite, errcb) {
	if (selected_device)
		selected_device.send(dataToWrite, undefined, errcb);
	else
		errcb('设备未加载');
}

export function printSticker0(item, n, errcb) {
	const s =
	`^XA
	^BY3,3,70^FT40,60^BCN,,Y,N
	^FD>;${item.pid}^FS
	^CW1,E:SIMSUN.FNT
	^CI28
	^FO40,110^A1N,30,30^FD${item.pii.username}^FS
	^FO180,110^A1N,30,30^FD${item.pii.gender}^FS
	^FO40,140^A1N,30,30^FDDOB: ${item.pii.dob}^FS
	^FO40,170^A1N,24,24^FDTEL: ${item.pii.phone}^FS
	^PQ${n}
	^XZ`;
	writeToSelectedPrinter(s, errcb);
}

export function printSticker(item, n, errcb) {
const s =
`^XA
^CW1,E:SIMSUN.FNT
^CI28
^FO40,10^A1N,30,30^FD${item.pii.username}^FS
^FO180,10^A1N,30,30^FD${item.pii.gender}(${item.age}岁)^FS
^FO40,40^A1N,30,30^FDMRN: ${item.pid}^FS
^FO40,70^A1N,30,30^FDDOB: ${item.pii.dob}^FS
^FO40,100^A1N,24,24^FDTEL: ${item.pii.phone}^FS
^FO40,130^A1N,30,30^FD${item.insurancetxt}^FS
^PQ${n}
^XZ`;
	writeToSelectedPrinter(s, errcb);
}
