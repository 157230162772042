<template>
	<div v-if="authed">
		<splitpanes horizontal class="default-theme" style="height:calc(100vh - 52px)" @resized="setTableHeight" v-resize="setTableHeight">
			<pane min-size="25" size="45" ref="toppane">
					<v-data-table :headers="headers" :items="items" item-key="_id" v-model="selected"
						:options.sync="options" :server-items-length="totalItems"
    					:footer-props="{ itemsPerPageOptions: [10, 30, 50] }" fixed-header :height="tableheight"
						:loading="loading" loading-text="加载中..." show-select single-select>
						<template v-slot:top>
							<div class="d-flex flex-wrap" ref="tabletopbar">
								<v-text-field placeholder="姓名" v-model.trim="pname" dense clearable hide-details style="flex: 0 1 auto" class="ml-4"
									append-icon="search" @click:append="fetchData" @keyup.enter.native="fetchData"/>
							</div>
							<v-divider/>
						</template>
						<template v-slot:item.age="{ item }">
							{{getAge(item)}}
						</template>
						<template v-slot:item.opts="{ item }">
							{{getOptsStr(item)}}
						</template>
						<template v-slot:item.insurance="{ item }">
							<span :class="item.payment.insurance ? 'teal--text text--accent-4 font-weight-bold' : 'black--text'">{{getInsurance(item)}}</span>
						</template>
					</v-data-table>
			</pane>
			<pane min-size="20" size="55" v-if="(selected.length > 0)">
				<div style="max-height:100%" class="overflow-y-auto pl-4">
                    <v-btn class="mt-4" color="primary" @click.stop="printSticker">打印</v-btn>
	            </div>
			</pane>
		</splitpanes>
    </div>
    <div v-else>
        无此权限
    </div>
</template>

<script>
	import {formatTime, formatDate, calculateAge} from '../utils'
	import {printSticker} from '../zebra'
	import { Splitpanes, Pane } from 'splitpanes'
	import 'splitpanes/dist/splitpanes.css'

    export default {
        data() {
            return {
                authed: false,
                headers: [
                    {text:'病案号', value:'pid', width:80},
                    {text:'姓名', value:'pii.username', width:120},
                    {text:'性别', value:'pii.gender', width:80},
                    {text:'年龄', value:'age', width:80},
                    {text:'电话', value:'pii.phone', width:100},
                    {text:'证件类型', value:'pii.idtype', width:100},
                    {text:'证件号', value:'pii.idcard', width:120},
                    {text:'套餐内容', value:'opts', width:240, sortable: false},
                    {text:'支付方', value:'insurance', width:150},
                    {text:'健康顾问', value:'consultantName', width:100},
                    {text:'订单号', value:'_id', width:100},
                ],
                items: [],
                loading: false,
				selected: [],
				options: {},
				totalItems: 0,
				pname: '',
				tableheight: undefined,
            }
        },
		watch: {
			options: {
				handler () {
					this.fetchData()
				},
				deep: true,
			},
			selected(newitem, olditem) {
				if (newitem.length === 0) return;
			}
		},
        mounted() {
			this.authed = this.$hasPrivilege(['打印条码', '术中结算', '健康顾问']);
			if (!this.authed) return;
			this.fetchData();
		},
        methods: {
			setTableHeight() {
				if (!this.$vuetify.breakpoint.mobile && this.selected.length === 0) {
					this.tableheight = undefined;
					return;
				}
				const tableFooterHeight = 59;
				const ttbh = this.$refs.tabletopbar.getBoundingClientRect().height;
				setTimeout(() => {
					const paneHeight = this.$refs.toppane.$el.getBoundingClientRect().height;
					this.tableheight = paneHeight - tableFooterHeight - ttbh;
					if (this.selected.length > 0) this.$nextTick(() => this.$vuetify.goTo(".v-data-table__selected", { container: ".v-data-table__wrapper" }) );
				}, 200)
			},
            getAge(item) {
                return calculateAge(item.pii.dob, item.progress.order);
            },
            getOptsStr(item) {
				return item.opts.GIE + '/' + item.opts.ANES + '/' + item.opts.PRO + (item.opts.TX ? '/' + item.opts.TX : '');
			},
			getInsurance(item) {
				return item.payment.insurance && item.payment.insurer ? `${item.payment.insurer} - ${item.payment.insurance}` : '自费';
			},
			async fetchData() {
				const db = this.$tcbapp.database();
				const _ = db.command;
				let filter = [{"payment.actual":_.exists(true)}];
				if (this.$hasPrivilege(['打印条码','术中结算'])) {
					//admin也有此权限
				} else if (this.$hasPrivilege('健康顾问')) {
					const u = this.$getCurrentUser();
					this.consultantId = u.consultantId;
					filter.push({consultantId: u.consultantId});
				}
				if (this.pname) {
					filter.push({'pii.username':this.pname});
				}
				filter.push({hide:_.neq(true)});
				const f = filter;
				this.loading = true;
				try {
                    const countRes = await db.collection('wp2order').where(_.and(f)).count();
					this.totalItems = countRes.total;
					const { sortBy, sortDesc, page, itemsPerPage } = this.options;
					const res = await db.collection('wp2order').where(_.and(f)).orderBy(sortBy[0]||'_', sortDesc[0] ? 'desc' : 'asc')
						.skip((page-1) * itemsPerPage).limit(itemsPerPage)
						.get();
					this.items = res.data;
                    /*
					if (this.selected.length > 0) {
						const s = this.items.find(x => x._id === this.selected[0]._id);
						this.selected = s ? [s] : [];
					}
                    */
				} catch(err) {
					console.error(err);
				}
				this.loading = false;
			},
			async printSticker() {
                const item = this.selected[0];
                item.age = calculateAge(item.pii.dob);
				item.insurancetxt = this.getInsurance(item);
				const res = await this.$dialog.prompt({
					title: '打印条码',
					text: '数量',
					rules: [(v) => parseInt(v) !== NaN || '请输入有效数字'],
					value: '1',
					textField: {
						// Any addtional props/attrs that will be binded to v-text-field component
						type: 'number',
						max: '20',
						min: '1'
					},
					width: 200,
					actions: {false:'取消', true:{text:'打印',color:'primary'}}
				});
				const n = parseInt(res);
				if (!n || n < 0 || n > 20) return;
				printSticker(item, n, err => {
					console.error(err);
					this.$dialog.error({title:'打印失败', text:err});
				});
				return;
				const s =
`^XA
^BY3,3,70^FT40,60^BCN,,Y,N
^FD>;${item.pid}^FS
^CW1,E:SIMSUN.FNT
^CI28
^FO40,110^A1N,30,30^FD${item.pii.username}^FS
^FO180,110^A1N,30,30^FD${item.pii.gender}^FS
^FO280,110^A1N,30,30^FD${item.age}岁^FS
^FO40,140^A1N,30,30^FD${item.insurancetxt}^FS
^PQ${n}
^XZ`;
//^FO40,140^A1N,30,30^FDDOB: ${item.pii.dob}^FS
//^FO40,170^A1N,24,24^FDTEL: ${item.pii.phone}^FS
				writeToSelectedPrinter(s, err => {
					this.$dialog.error({title:'打印失败', text:err});
					console.error(err);
				});
			},
		},
        components: { Splitpanes, Pane }
    }
</script>

<style>
.splitpanes.default-theme .splitpanes__pane {
    background-color: white;
}
.splitpanes.default-theme .splitpanes__splitter {
    background-color: #eee;
}
.splitpanes.default-theme .splitpanes__splitter:after,
.splitpanes.default-theme .splitpanes__splitter:before {
    background-color: rgba(0, 0, 0, .25);
}
.default-theme.splitpanes--horizontal>.splitpanes__splitter,
.default-theme .splitpanes--horizontal>.splitpanes__splitter {
    height: 8px;
}
.default-theme.splitpanes--horizontal>.splitpanes__splitter:after,
.default-theme .splitpanes--horizontal>.splitpanes__splitter:after,
.default-theme.splitpanes--horizontal>.splitpanes__splitter:before,
.default-theme .splitpanes--horizontal>.splitpanes__splitter:before {
    width: 60px;
    height: 1.5px
}
</style>